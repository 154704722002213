import { createContext, useContext, useState, useEffect } from "react";
import { login as loginApi } from "../../api/dashboard/auth";
import { storageKeys } from "src/utils";
import { jwtDecode } from "jwt-decode";

const AuthContext = createContext(0);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);
  const [accessToken, setAccessToken] = useState(undefined);

  useEffect(() => {
    const accessTokenFromStorage = localStorage.getItem(
      storageKeys.ACCESS_TOKEN
    );
    const userFromStorage = localStorage.getItem(storageKeys.USER);

    if (
      (userFromStorage || accessTokenFromStorage) &&
      isTokenExpired(accessTokenFromStorage)
    ) {
      localStorage.removeItem(storageKeys.ACCESS_TOKEN);
      localStorage.removeItem(storageKeys.USER);
      setUser(undefined);
      setAccessToken(undefined);
      window.location.href = "/login";
    }

    if (userFromStorage && accessTokenFromStorage) {
      setUser(JSON.parse(userFromStorage));
      setAccessToken(accessTokenFromStorage);
    }
  }, []);

  const login = async ({ username, password }) => {
    const { user, accessToken } = await loginApi({ username, password });
    setUser(user);
    setAccessToken(accessToken);

    localStorage.setItem(storageKeys.ACCESS_TOKEN, accessToken);
    localStorage.setItem(storageKeys.USER, JSON.stringify(user));

    switch (user.userType) {
      case "eventManager":
        window.location.href = "/portal/events";
        break;
      default:
        window.location.href = "/portal/reception";
    }
  };

  const logout = async () => {
    localStorage.removeItem(storageKeys.ACCESS_TOKEN);
    localStorage.removeItem(storageKeys.USER);
    setUser(undefined);
    setAccessToken(undefined);
    window.location.href = "/login";
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

const isTokenExpired = (token) => {
  if (!token) return true; // No token means expired

  const decoded = jwtDecode(token);
  const currentTime = Date.now() / 1000; // Convert to seconds

  return decoded.exp < currentTime; // Check if the token is expired
};
