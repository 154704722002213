import axios from "axios";
import { useFormik } from "formik/dist";
import { useEffect, useState } from "react";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import * as yup from "yup";
import "./index.scss";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import { notify } from "src/utils";

import { MdPhotoCamera } from "react-icons/md";
import { useRef } from "react";
import { payForEventTicket } from "src/api/space/events";
import Lottie from "react-lottie";
import SimpleLoader from "src/assets/lotties/simple-loader.json";
import Loader from "../../components/Loader";

const EventRegistratioModal = ({
  isvisible,
  setIsVisible,
  userTicketQuantity,
  selectedPaymentMethod,
  totalPrice,
  setSuccessBoxIsVisible,
  eventId,
}) => {
  const wrapperRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const phoneNumber = "01226568908";
  const copyNumberToClipboard = (copied) => {
    navigator.clipboard
      .writeText(copied)
      .then(() => {
        notify("Copied", "success");
      })
      .catch((err) => {
        console.error("Failed to copy number: ", err);
      });
  };
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const [userData, setUserData] = useState({});
  const [paymentDetailsModal, setpaymentDetailsModal] = useState("");

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    phone: yup
      .string()
      .matches(/^(01)[0125][0-9]{8}$/, "Invalid phone number")
      .required("Phone number is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      resetForm();

      setUserData(values);

      if (selectedPaymentMethod === "visa/paymob") {
        GetVisaPaymentKey();
      } else if (selectedPaymentMethod === "cash") {
        submitForm();
      } else {
        GetPaymentResponse();
      }
    },
  });

  useEffect(() => {
    setUserData({
      ...userData,
      paymentMethod: selectedPaymentMethod,
      quantity: Number(userTicketQuantity),
      eventId: parseInt(eventId),
    });
  }, [file, formik.values]);

  const GetVisaPaymentKey = async () => {
    const { data } = await axios.post(
      `https://eoxk8fjd8yh4e33.m.pipedream.net`,
      userData
    );

    window.location.replace(
      `https://accept.paymobsolutions.com/api/acceptance/iframes/${data.iframeId}?payment_token=${data.paymentToken}`
    );
  };
  const GetPaymentResponse = () => {
    setIsVisible(false);
    setpaymentDetailsModal(true);
  };

  const submitForm = async () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("screenshot", file);
    Object.entries(userData).map(([key, value]) => {
      formData.append(key, value);
    });
    const res = await payForEventTicket(formData, eventId);
    if (res === undefined) {
      setIsLoading(false);
    } else {
      notify("We will send you conformation on whatsapp soon", "success");

      setIsVisible(false);
      setpaymentDetailsModal(false);

      setSuccessBoxIsVisible(true);
    }
  };

  return (
    <>
      <ModalLayout
        show={isvisible}
        onAbort={() => {
          setIsVisible(false);
        }}
        title="Your Details"
      >
        <form onSubmit={formik.handleSubmit} className=" m-auto">
          <input
            id="name"
            name="name"
            type="text"
            placeholder="Enter Name"
            onChange={formik.handleChange}
            value={formik.values.name}
            onBlur={formik.handleBlur}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="error">{formik.errors.name}</div>
          ) : null}

          <input
            id="email"
            name="email"
            type="text"
            placeholder="Enter Email"
            onChange={formik.handleChange}
            value={formik.values.email}
            onBlur={formik.handleBlur}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="error">{formik.errors.email}</div>
          ) : null}

          <input
            id="phone"
            name="phone"
            type="tel"
            onChange={formik.handleChange}
            value={formik.values.phone}
            onBlur={formik.handleBlur}
            placeholder="EX :01 XXXXXXXXX"
          />
          {formik.touched.phone && formik.errors.phone ? (
            <div className="error">{formik.errors.phone}</div>
          ) : null}
          <ActionButton
            type="submit"
            className=" btn  my-3 border-0 w-100 m-auto popup-btn"
            title={selectedPaymentMethod === "cash" ? "Submit" : "Next"}
            isActive={Object.values(formik.values).every(
              (value) => value !== ""
            )}
            style={{
              paddingLeft: "25px ",
              paddingRight: "25px",
              paddingTop: "7px",
              paddingBottom: "7px",
              fontSize: "16px",
              fontFamily: "AoboshiOne",
              margin: "30px auto 10px",
            }}
            backgroundColor="colorSecondaryDarker"
          >
            {selectedPaymentMethod === "cash" ? "Submit" : "Next"}
          </ActionButton>
        </form>
      </ModalLayout>

      <ModalLayout
        show={paymentDetailsModal}
        onAbort={() => {
          setpaymentDetailsModal(false);
        }}
      >
        <div className="paymentDetailsModal">
          {selectedPaymentMethod === "telda" ? (
            <div>
              Please send {totalPrice} L.E to Telda account
              <span
                onClick={() => {
                  copyNumberToClipboard(phoneNumber);
                }}
              >
                {phoneNumber}
              </span>
            </div>
          ) : selectedPaymentMethod === "mobile-wallet" ? (
            <div>
              Please send {totalPrice} L.E to Mobile Wallet
              <span
                onClick={() => {
                  copyNumberToClipboard(phoneNumber);
                }}
              >
                {phoneNumber}
              </span>
            </div>
          ) : (
            <div>
              Please send {totalPrice} L.E to instaPay account
              <span
                onClick={() => {
                  copyNumberToClipboard(phoneNumber);
                }}
              >
                {phoneNumber}
              </span>
              or &nbsp;
              <span
                onClick={() => {
                  copyNumberToClipboard("elashmawydev");
                }}
              >
                elashmawydev@instaPay
              </span>
            </div>
          )}

          {file ? (
            <img
              src={URL.createObjectURL(file)}
              alt=""
              width={100}
              height={100}
              style={{ marginTop: "30px", marginBottom: "30" }}
            />
          ) : (
            <div
              className="screnshoot-container"
              onClick={() => {
                wrapperRef.current.click();
              }}
            >
              <MdPhotoCamera
                style={{
                  fontSize: "40px",
                  color: "#aa7258",
                  marginTop: "20px",
                }}
              />
              <h6
                style={{
                  fontFamily: "AoboshiOne",
                  color: "#aa7258",
                  fontSize: "10px",
                }}
              >
                click here to
              </h6>
              <h6
                style={{
                  fontFamily: "AoboshiOne",
                  color: "#aa7258",
                }}
              >
                Attach Screenshot
              </h6>
            </div>
          )}
        </div>
        <input
          className="d-none"
          ref={wrapperRef}
          type="file"
          onChange={handleFileChange}
        />

        <ActionButton
          isActive={file != null}
          title={isLoading ? <Loader type="simple" /> : "Submit"}
          onClick={submitForm}
          style={{
            paddingLeft: "25px ",
            paddingRight: "25px",
            paddingTop: "7px",
            paddingBottom: "7px",
            fontSize: "16px",
            fontFamily: "AoboshiOne",
            margin: "30px auto 10px",
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};
export default EventRegistratioModal;
