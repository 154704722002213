import { executeWithAuth } from "src/api";
import { API_URL_DASHBOARD } from "src/utils";

export const createCampaign = (campaign) =>
  executeWithAuth({
    method: "POST",
    url: `${API_URL_DASHBOARD}/campaigns`,
    body: campaign,
  }).then((data) => data?.campaign);

export const getCampaignByEventId = (eventId) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/campaigns/events/${eventId}`,
  }).then((data) => data?.campaign);

export const getCampaignById = (campaignId) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/campaigns/${campaignId}`,
  }).then((data) => data?.campaign);

export const createCampaignLead = ({ campaignId, spaceCustomerId }) =>
  executeWithAuth({
    method: "POST",
    url: `${API_URL_DASHBOARD}/campaigns/${campaignId}/leads`,
    body: { spaceCustomerId },
  }).then((data) => data?.campaign);

export const getLeadsByCampaignId = ({ campaignId }) =>
  executeWithAuth({
    method: "GET",
    url: `${API_URL_DASHBOARD}/campaigns/${campaignId}/leads`,
  }).then((data) => data?.leads || []);
