import { useEffect, useState } from "react";
import { createKitchenProduct } from "src/api/dashboard/kitchen";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import Select from "src/modules/dashboard/components/Select";
import { kitchenProductCategories, queryClient } from "src/utils";

const NewProductModal = ({ isVisable, setIsVisible }) => {
  const [newProduct, setNewProduct] = useState({
    title: "",
    category: kitchenProductCategories.SOFT_DRINKS,
    price: 0,
  });

  const handleInputChange = (e) => {
    setNewProduct({ ...newProduct, [e.target.name]: e.target.value });
  };

  return (
    <>
      <ModalLayout
        show={isVisable}
        onAbort={() => {
          setNewProduct({
            title: "",
            category: kitchenProductCategories.SOFT_DRINKS,
            price: 0,
          });
          setIsVisible(false);
        }}
        title={"New Product"}
      >
        <Input
          value={newProduct.title}
          onChange={handleInputChange}
          name={"title"}
          placeholder={"Name"}
        />

        <Input
          onChange={(e) =>
            setNewProduct({
              ...newProduct,
              price: parseFloat(e.target.value),
            })
          }
          name={"price"}
          placeholder={"Price"}
        />

        <Select
          onChange={(category) =>
            setNewProduct({
              ...newProduct,
              category,
            })
          }
          value={newProduct.category}
          style={{
            marginTop: 5,
          }}
          options={Object.entries(kitchenProductCategories).map(
            ([key, value]) => ({
              label: `${key
                .split("_")
                .map((c) => c[0].toUpperCase() + c.substr(1).toLowerCase())
                .join(" ")}`,
              value,
            })
          )}
        />
        <ActionButton
          onClick={async () => {
            if (await createKitchenProduct(newProduct)) {
              queryClient.invalidateQueries({
                queryKey: ["kitchenProducts"],
              });
              setNewProduct({
                title: "",
                category: kitchenProductCategories.SOFT_DRINKS,
                price: 0,
              });
              setIsVisible(false);
            }
          }}
          title="Add Product"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "AoboshiOne",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};

export default NewProductModal;
