import React, { useEffect } from "react";
import * as yup from "yup";
import RightVectorWithTitle from "src/assets/images/right-vector-with-aura.png";
import LeftVectorWithTitle from "src/assets/images/Left-top-with-aura.png";
import LeftBottomVendor from "src/assets/images/Left-vector.png";
import RightBottomCorner from "src/assets/images/bottom-right-corner.png";
import { FaCheck } from "react-icons/fa";
import "./index.scss";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import { useState } from "react";
import { useFormik } from "formik";
import { redirect, useParams } from "react-router-dom";
import {
  getFormDetails,
  registerEventForm,
} from "src/api/dashboard/eventForms";
import dayjs from "dayjs";
import Loader from "src/modules/space/components/Loader";

const EventForm = () => {
  const { formId } = useParams();
  const [formDetails, setFormDetails] = useState(null);
  const [addtionalFields, setAdditionalFields] = useState([]);

  useEffect(() => {
    (async () => {
      const responde = await getFormDetails(formId);
      setFormDetails(responde);

      if (responde) {
      } else {
        window.location.href ="/";
      }
    })();
  }, []);

  const [registerData, setregisterData] = useState(null);

  const validationSchema = yup.object().shape({
    name: yup.string().required("Name is required"),
    phone: yup
      .string()
      .matches(/^(01)[0125][0-9]{8}$/, "Invalid phone number")
      .required("Phone number is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const { formRegistration } = await registerEventForm(
        { ...values, addtionalFields },
        formId
      );
      setregisterData(formRegistration);
    },
  });

  return (
    <div>
      {formDetails != null ? (
        <div className="event-form">
          <div className="corners">
            <div className="right-vector-with-aura  ">
              <img src={RightVectorWithTitle} alt="" />
            </div>
            <div className="left-vector-with-aura-payment ">
              <img src={LeftVectorWithTitle} alt="" />
            </div>
            <div className="left-bottom-corner ">
              <img src={LeftBottomVendor} alt="" />
            </div>
            <div className="right-bottom-corner ">
              <img src={RightBottomCorner} alt="" />
            </div>
          </div>

          <div className="registration-form">
            <h1>Registration Form</h1>
            <p>We made it easy for Aura’s lovers ❤️</p>
            <div className="form-container">
              <h3>{formDetails?.form.title}</h3>
              <p>
                Friday 2 Feb | 
                {dayjs(formDetails?.form?.date).format("hh:mm A")}
              </p>

              {registerData === null ? (
                <div>
                  {formDetails?.form?.note ? (
                    <div className="note">
                      <h4>Note</h4>
                      <h5>Entrance fees are 100L.E</h5>
                    </div>
                  ) : (
                    ""
                  )}
                  <form action=" " onSubmit={formik.handleSubmit}>
                    <input
                      type="text"
                      name="name"
                      placeholder="Name"
                      onChange={formik.handleChange}
                      value={formik.values.name}
                    />
                    {formik.touched.name && formik.errors.name ? (
                      <div className="error">{formik.errors.name}</div>
                    ) : null}
                    <input
                      type="tel"
                      name="phone"
                      placeholder="Phone"
                      onChange={formik.handleChange}
                      value={formik.values.phone}
                      style={{ marginTop: "20px" }}
                    />
                    {formik.touched.phone && formik.errors.phone ? (
                      <div className="error">{formik.errors.phone}</div>
                    ) : null}
                    <input
                      type="text"
                      placeholder="Email"
                      name="email"
                      onChange={formik.handleChange}
                      value={formik.values.email}
                      style={{ marginTop: "20px" }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="error">{formik.errors.email}</div>
                    ) : null}

                    <ActionButton
                      title="Submit"
                      style={{
                        background: "#3F4631",
                        fontFamily: "AoboshiOne",
                        width: "165px",
                        height: "44px",
                        marginBottom: "20px",
                        marginTop: "40px",
                      }}
                    />
                  </form>
                </div>
              ) : (
                <div className="success">
                  <div className="check-icon">
                    <FaCheck />
                  </div>
                  <h2>Registered Successfully</h2>
                  <p>
                    {registerData?.customer.name}, we are gonna be waiting for
                    you
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <Loader />
      )} 
    </div>
  );
};
export default EventForm;
