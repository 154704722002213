import { useEffect } from "react";
import { useQuery } from "react-query";
import {
  createCampaignLead,
  getCampaignByEventId,
  getLeadsByCampaignId,
} from "src/api/dashboard/campaigns";
import Loader from "src/modules/space/components/Loader";
import { queryClient } from "src/utils";

const dayjs = require("dayjs");
const { FaWhatsapp } = require("react-icons/fa");
const { getSpaceCustomers } = require("src/api/dashboard/space");
const {
  default: ActionButton,
} = require("src/modules/dashboard/components/ActionButton");
const {
  default: DataTable,
} = require("src/modules/dashboard/components/InfiniteDataTable");

const MarketingCampaignTable = ({ event }) => {
  const {
    data: campaign,
    isLoading: isLoadingCampaign,
    isSuccess: isCampaignFetched,
  } = useQuery(["campaigns", event?._id], () =>
    getCampaignByEventId(event?._id),
  );

  const { data: leads, isLoading: isLoadingLeads } = useQuery(
    ["leads", campaign?._id],
    () => getLeadsByCampaignId({ campaignId: campaign?._id }),
    {
      enabled: isCampaignFetched,
    },
  );

  if (isLoadingCampaign || isLoadingLeads) {
    return <Loader size={200} />;
  }

  return (
    <>
      <div className="title-label">Marketing Campaign</div>
      <div className="campaign-table">
        <DataTable
          dataKey={["spaceCustomers"]}
          fetchData={getSpaceCustomers}
          columns={[
            { title: "#", selector: (row) => row.customerId },
            { title: "Name" },
          ]}
          actions={[
            {
              component: (row) => (
                <ActionButton
                  isActive={
                    !leads?.find((lead) => lead?.spaceCustomerId === row._id)
                  }
                  onClick={async () => {
                    window.open(
                      `https://wa.me/${row?.phone.slice(1)}?text=${encodeURI(`Join Us for an Exciting Event at Aura Space!
  Hello ${row?.name.split(" ")[0]}! We’re thrilled to invite you to our upcoming ${event?.title}!
  Event Details:
      •	Date: ${dayjs(event?.date).format("dddd, DD MMMM")}
      •	Time: ${dayjs(event?.date).format("hh:mma")}
      •	Location: Aura Space, Maadi
  Get ready for a night filled with joy
  Click here to secure your ticket now: https://auraspace.art/events/${event?.eventId}/payment
  Don’t miss out on this fantastic experience! We can’t wait to see you there!
  Best,
  The Aura Space Team`)}`,
                      "_blank",
                    );

                    await createCampaignLead({
                      campaignId: campaign?._id,
                      spaceCustomerId: row._id,
                    });

                    await queryClient.invalidateQueries([
                      "leads",
                      campaign?._id,
                    ]);
                  }}
                  title={<FaWhatsapp />}
                  backgroundColor="colorPrimaryLight"
                />
              ),
            },
          ]}
        />
      </div>
    </>
  );
};

export default MarketingCampaignTable;
