import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import PaymentMehthodInput from "./components/PaymentMethodInput";
import SelectKitchenItems from "../SelectKitchenItems";
import dayjs from "dayjs";
import { useEffect, useState } from "react";

import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";

import "./index.scss";
import { paymentMethods } from "src/utils/constants";
import { payForSpacePass } from "src/api/dashboard/space";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import { queryClient } from "src/utils";

const PaymentSpacePassModal = ({ isVisible, setIsVisible, spacePass }) => {
  const [spacePassDetails, setSpacePassDetails] = useState({
    ...spacePass,
    endTime: dayjs().toISOString(),
  });
  const [spacePassPrice, setSpacePassPrice] = useState(0);
  const [kitchenPrice, setKitchenPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [passDuration, setPassDuration] = useState("");
  const [timeSpent, setTimeSpent] = useState(0);
  const [kitchenProducts, setKitchenProducts] = useState([]);
  const [payments, setPayments] = useState([]);
  const [editNumberOfFriends, setEditNumberOfFriends] = useState(false);

  const handleDoubleClick = () => {
    setEditNumberOfFriends(true);
  };

  useEffect(() => {
    let calculatedSpacePassPrice = 0;
    const timeSpent = (
      dayjs().diff(spacePassDetails?.startTime, "minutes") / 60
    ).toFixed(2);

    if (timeSpent < 4.8) {
      setPassDuration("half day");
      calculatedSpacePassPrice =
        spacePassDetails?.friends === 0
          ? 100
          : 100 + 100 * spacePassDetails?.friends;
    } else {
      setPassDuration("full day");
      calculatedSpacePassPrice =
        spacePassDetails?.friends === 0
          ? 200
          : 200 + 200 * spacePassDetails?.friends;
    }

    setTimeSpent(timeSpent);
    setSpacePassPrice(calculatedSpacePassPrice);
  }, [spacePassDetails?.friends, spacePassDetails?.startTime]);

  useEffect(() => {
    const newTotalPrice = spacePassPrice + kitchenPrice;
    setTotalPrice(newTotalPrice);

    const totalAmountInPayments = payments.reduce(
      (acc, curr) => acc + curr.amount,
      0
    );
    setRemainingAmount(newTotalPrice - totalAmountInPayments);
  }, [spacePassPrice, kitchenPrice, spacePassDetails, payments]);

  useEffect(() => {
    setPayments([]);
  }, [spacePassPrice, kitchenPrice]);
  const paySpacePass = async () => {
    const res = await payForSpacePass({
      kitchenProducts,
      spacePass: spacePassDetails,
      passAmount: spacePassPrice,
      spacePassId: spacePassDetails._id,
      payments:
        spacePassPrice === 0 && kitchenPrice === 0
          ? [
              {
                paymentMethod: paymentMethods.CASH,
                amount: 0,
                currency: "EGP",
              },
            ]
          : payments,
      endTime: dayjs().format("YYYY-MM-DDTHH:mm:ss.SSSZ"),
    });
    if (res !== undefined) {
      setIsVisible(false);
      queryClient.invalidateQueries({ queryKey: ["spacePasses"] });
    }
  };

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => setIsVisible(false)}
        title="Space Payment"
        className="space-pass-payment-modal"
      >
        {spacePassDetails?.customer?.customerId && (
          <p
            style={{
              color: "#6D7254",
              fontSize: "12px",
              textAlign: "center",
              marginBottom: 10,
            }}
          >
            Customer Id{" "}
            <span style={{ fontWeight: 700 }}>
              #{spacePassDetails?.customer?.customerId}
            </span>
          </p>
        )}
        <div className="customer-data">
          <div className="customer-data-item">
            <span>Name: </span>
            <span>{spacePassDetails?.customer?.name}</span>
          </div>
          <div className="customer-data-item">
            <span>Phone: </span>
            <span>{spacePassDetails?.customer?.phone}</span>
          </div>
          <div className="customer-data-item" onDoubleClick={handleDoubleClick}>
            <span>Friends: </span>
            {editNumberOfFriends ? (
              <>
                <select
                  onChange={(selectedOption) => {
                    console.log("Selected value:", selectedOption.target.value);
                    setSpacePassDetails({
                      ...spacePassDetails,
                      friends: parseInt(selectedOption.target.value),
                    });
                  }}
                  className="friends-dropdown"
                >
                  {Array.from(Array(21).keys())?.map((number) => (
                    <option key={number} value={number}>
                      {number}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <span>{spacePassDetails?.friends}</span>
            )}
          </div>
          <div className="customer-data-item">
            <span>Hours: </span>
            <span>{timeSpent}</span>
          </div>
          <div className="customer-data-item">
            <span>Start Time: </span>
            <span>
              <span style={{ fontWeight: 500 }}>
                {dayjs(spacePassDetails?.startTime).format("hh:mm a")}
              </span>
            </span>
          </div>
        </div>
        <div
          className="line"
          style={{
            width: "99%",
            height: "0.1px",
            backgroundColor: "#DDC7BA",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></div>
        <div className="endtime-picker">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DateTimePicker"]}>
              <DateTimePicker
                closeOnSelect
                label={
                  <p
                    style={{
                      color: "#6D7254",
                      fontSize: 12,
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                  >
                    End Time
                  </p>
                }
                value={dayjs(spacePassDetails.endTime)}
                onChange={(selectedTime) => {
                  setSpacePassDetails({
                    ...spacePassDetails,
                    endTime: selectedTime.toISOString(),
                  });
                  console.log("endtime" + { spacePassDetails });
                }}
                className="date-picker"
              />
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <div
          className="line"
          style={{
            width: "99%",
            height: "0.1px",
            backgroundColor: "#DDC7BA",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></div>

        <SelectKitchenItems
          onChangeKitchenTotalPice={setKitchenPrice}
          onChangeSpacePassPrice={setSpacePassPrice}
          spacePassPrice={spacePassPrice}
          spacePass={spacePassDetails}
          passDuration={passDuration}
          setKitchenProducts={setKitchenProducts}
        />

        <div
          className="line"
          style={{
            width: "70%",
            height: "0.1px",
            backgroundColor: "#DDC7BA",
            margin: "auto",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        ></div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "5px",
            fontSize: "18px",
            fontFamily: "AoboshiOne",
          }}
        >
          <div>{"Total"}</div> <div> {totalPrice}</div>
        </div>
        <div
          style={{
            fontSize: "14px",
            fontFamily: "AoboshiOne",
            color: "#6D7254",
            marginTop: "10px",
          }}
        >
          <h5 style={{ textAlign: "center" }}>Payment Methods</h5>
          <div style={{ marginTop: "30px" }}>
            {Object.values(paymentMethods).map((paymentMethod) => (
              <PaymentMehthodInput
                value={
                  payments?.find((p) => p.paymentMethod === paymentMethod)
                    ?.amount || 0
                }
                remainingAmount={remainingAmount}
                totalAmount={totalPrice}
                paymentMethod={paymentMethod}
                onChange={(payment) => {
                  if (payment.amount === 0) {
                    // Ignore & remove if already exists in the payments[]
                    setPayments([
                      ...payments?.filter(
                        (p) => p.paymentMethod !== payment.paymentMethod
                      ),
                    ]);
                    return;
                  }

                  // If this last payment makes total payments higher than totalPrice, set back to remaining
                  const remainingFromOtherPayments =
                    totalPrice -
                    payments
                      .filter((p) => p.paymentMethod !== payment.paymentMethod)
                      .reduce((acc, curr) => acc + curr.amount, 0);

                  if (payment.amount > remainingFromOtherPayments) {
                    payment.amount = remainingFromOtherPayments;
                  }

                  // Change the value if the payment already exists
                  const newPayments = [
                    ...payments.filter(
                      (p) => p.paymentMethod !== payment.paymentMethod
                    ),
                    payment,
                  ];
                  setPayments(newPayments);
                }}
              />
            ))}
          </div>
          <ActionButton
            title={
              spacePassPrice === 0 && kitchenPrice === 0
                ? "Pay Nothing!"
                : "Submit"
            }
            onClick={paySpacePass}
            className="submit-btn"
            backgroundColor="colorSecondaryDarker"
            isActive={remainingAmount > 0 ? false : true}
          />
        </div>
      </ModalLayout>
    </>
  );
};

export default PaymentSpacePassModal;
