import { Link } from "react-router-dom";
import Image404 from "../../../../assets/images/404.svg";

const ErrorPage404 = () => {
  return (
    <div className="bg-primary-light font-secondary flex h-screen flex-col items-center justify-center text-white">
      {/* <h1>404</h1>
      <p>page not found</p> */}
      <img src={Image404} alt="404 | page not found" className="w-50" />
      <h1 className="font-forth text-primary-dark mt-6 text-xl md:text-4xl">
        page not found
      </h1>
      <div className="mt-10 flex justify-between space-x-4 md:mt-20">
        <Link
          to="/login"
          className="font-third text-primary-light bg-secondary-light text-md hover:bg-primary-dark hover:text-secondary-light text-md pb:2 w-[120px] rounded-md border-none pb-2 pt-2 text-center hover:no-underline md:pb-1"
        >
          Login
        </Link>
        <Link
          to="/"
          className="font-third text-primary-light bg-secondary-light text-md hover:bg-primary-dark hover:text-secondary-light text-md pb:2 w-[120px] rounded-md border-none pb-2 pt-2 text-center hover:no-underline md:pb-1"
        >
          Home
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage404;
