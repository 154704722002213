import { useState } from "react";
import { DatePicker } from "rsuite";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import "./index.scss";
import { notify } from "src/utils";
import { FaCamera } from "react-icons/fa";

const CreateEventModal = ({ isVisible, setIsVisible }) => {
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [price, setPrice] = useState(null);
  const [seats, setSeats] = useState(null);
  const [date, setDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleCreateEvent = async () => {
    // Check if all details are submitted
    if (!title || !description || !price || !seats || !date) {
      notify("Please fill all fields", "error");
      return;
    }

    setIsLoading(true);
    try {
      setIsVisible(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => {
          // reset all fields
          setTitle(null);
          setDescription(null);
          setPrice(null);
          setSeats(null);
          setDate(null);

          setIsVisible(false);
        }}
        title="Create Event"
        className="create-event-modal"
      >
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          name={"title"}
          placeholder="Title"
        />
        <Input
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          name={"description"}
          placeholder="Description"
          isLongText
        />
        <Input
          value={seats}
          onChange={(e) => setSeats(e.target.value)}
          name={"seats"}
          placeholder="Seats"
          type="number"
          trailingLabel={"Tickets"}
          min={0}
        />
        <Input
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          name={"price"}
          placeholder="Price"
          type="number"
          trailingLabel={"EGP"}
          min={0}
        />
        <Input
          value={date}
          onChange={(e) => setDate(e.target.value)}
          name={"date"}
          placeholder="Date & Time"
          type="datetime-local"
          showPlaceholderOutside
          style={{ marginTop: "2rem" }}
        />

        <div className="upload-image-container">
          <FaCamera size={34} />
          <span>Click here to</span>
          <span>Upload Event Thumbnail</span>
        </div>
        <ActionButton
          onClick={handleCreateEvent}
          backgroundColor="colorPrimaryLight"
          disabled={
            isLoading || !title || !description || !price || !seats || !date
          }
          className="create-event-button"
          title={isLoading ? "Creating..." : "Create Event"}
        />
      </ModalLayout>
    </>
  );
};

export default CreateEventModal;
