import dayjs from "dayjs";
import { delay } from "lodash";
import { useState } from "react";
import { createSpacePass } from "src/api/dashboard/space";
import ActionButton from "src/modules/dashboard/components/ActionButton";
import Input from "src/modules/dashboard/components/Input";
import ModalLayout from "src/modules/dashboard/components/ModalLayout";
import Select from "src/modules/dashboard/components/Select";
import { knowAboutUsSources, queryClient } from "src/utils";

const RegisterSpacePassModal = ({ isVisible, setIsVisible }) => {
  const [newCustomerForm, setNewCustomerForm] = useState({
    name: "",
    phone: "",
    friends: 0,
    knowAboutUsSource: knowAboutUsSources.RETURNING_CUSTOMER,
  });

  const handleInput = (e) => {
    setNewCustomerForm({
      ...newCustomerForm,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <>
      <ModalLayout
        show={isVisible}
        onAbort={() => setIsVisible(false)}
        title="Register Space Customer"
      >
        <Input
          value={newCustomerForm.name}
          onChange={handleInput}
          name={"name"}
          placeholder="Name"
        />
        <Input
          value={newCustomerForm.phone}
          onChange={handleInput}
          name={"phone"}
          placeholder="Phone"
          type="tel"
        />

        <Select
          onChange={(friends) =>
            setNewCustomerForm({
              ...newCustomerForm,
              friends: parseInt(friends),
            })
          }
          defaultTitle="Number of Friends"
          // isTitleDisabled={false}
          value={newCustomerForm.friends}
          style={{
            marginTop: 5,
          }}
          options={Array.from(Array(11).keys()).map((v) => ({
            label: `${v}`,
            value: v,
          }))}
        />
        <Select
          onChange={(knowAboutUsSource) =>
            setNewCustomerForm({
              ...newCustomerForm,
              knowAboutUsSource,
            })
          }
          defaultTitle="How did you know about us?"
          // isTitleDisabled={false}
          value={newCustomerForm.knowAboutUsSource}
          style={{
            marginTop: 15,
          }}
          options={Object.entries(knowAboutUsSources).map(([key, value]) => ({
            label: `${key
              .split("_")
              .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
              .join(" ")}`,
            value,
          }))}
        />

        <ActionButton
          onClick={async () => {
            if (
              await createSpacePass({
                ...newCustomerForm,
                startTime: dayjs().toISOString(),
              })
            ) {
              await queryClient.invalidateQueries({
                queryKey: ["spacePasses"],
              });
              // Reset form
              setNewCustomerForm({
                name: "",
                phone: "",
                friends: 0,
                knowAboutUsSource: knowAboutUsSources.RETURNING_CUSTOMER,
              });

              setIsVisible(false);
            }
          }}
          title="Register Customer"
          style={{
            paddingTop: 10,
            paddingBottom: 10,
            fontSize: 16,
            fontFamily: "AoboshiOne",
            margin: "auto",
            marginTop: 30,
          }}
          backgroundColor="colorSecondaryDarker"
        />
      </ModalLayout>
    </>
  );
};

export default RegisterSpacePassModal;
