import { storageKeys } from "src/utils";
import axios from "axios";
import { notify } from "src/utils";
import { redirect } from "react-router-dom";

export const execute = async ({
  method = "GET",
  url,
  body,
  headers = {},
  query,
  notifySuccessMsg = false,
}) => {
  return axios(url, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...headers,
    },
    method,
    data: body,
    params: query,
  })
    .then((response) => response?.data)
    .then((response) => {
      if (response && response.status) {
        if (notifySuccessMsg) notify(response.message);
        return response?.data;
      } else {
        const error = new Error(response?.message);
        error.code = response?.code;
        throw error;
      }
    })
    .catch(async (error) => {
      if (
        ["INVALID_AUTH_TOKEN", "ACCESS_TOKEN_MISSING", "UNAUTHURIZED"].find(
          (c) => c === error?.code
        )
      ) {
        // Remove current credentials and redirect
        localStorage.removeItem(storageKeys.USER);
        localStorage.removeItem(storageKeys.USER_TOKEN);
        window.location.href = "/";
      }
      console.log("Error", { errorMsg: error.message, errorCode: error.code });
      notify(error?.response?.data?.error?.message || error.message, "error");
    });
};

export const executeWithAuth = (props) =>
  execute({
    ...props,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...(localStorage.getItem(storageKeys.ACCESS_TOKEN) && {
        Authorization: `Bearer ${localStorage.getItem(
          storageKeys.ACCESS_TOKEN
        )}`,
      }),
      ...props.headers,
    },
  });
